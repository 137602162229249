<template>
  <v-container class="py-7">
    <div class="heading mb-7">{{ title }}</div>

    <div class="notifications">
      <div v-for="(value, key, i) in notificationsByDate" :key="i">
        <p class="date">{{ key }}</p>
        <div
          v-for="(notification, index) in value"
          :key="index"
          class="notification-wrapper mb-4"
        >
          <h3 class="mb-2">
            {{ notification.push_title }}
          </h3>
          <p class="text">
            {{ notification.push_message }}
          </p>
          <p class="time-label">
            {{ getTime(notification.date_created) }}
          </p>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import uniq from 'lodash/uniq'

export default {
  computed: {
    notificationType() {
      return this.$route.query.type
    },

    title() {
      return this.notificationType === 'order' ? 'Заказы' : 'Скидки и акции'
    },

    notificationsByDate() {
      const notifications =
        this.notificationType === 'order'
          ? this.$store.getters['user/orderNotifications']
          : this.$store.getters['user/actionNotifications']

      const dateLabels = uniq(
        notifications.map((item) => this.formatDate(item.date_created))
      )
      const result = {}
      dateLabels.forEach((label) => {
        result[label] = notifications.filter((item) => {
          return this.formatDate(item.date_created) === label
        })
      })
      return result
    },
  },

  methods: {
    formatDate(date) {
      return dayjs(date).format('DD.MM.YYYY')
    },
    getTime(date) {
      return dayjs(date).format('H:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
.notifications {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.date {
  margin-bottom: 10px;
  font-size: 0.8rem;
  text-align: center;
  .theme--dark & {
    color: var(--v-secondary-base);
  }
}

.notification-wrapper {
  padding: 10px 12px;
  background-color: #eeeeee;
  border-radius: 12px;

  h3 {
    overflow-wrap: break-word;
  }
  .theme--dark & {
    background-color: var(--v-white-base);
  }
}

.text {
  font-size: 0.9rem;
  margin-bottom: 0;
  overflow-wrap: break-word;
  .theme--dark & {
    color: var(--v-secondary-base);
  }
}

.time-label {
  position: relative;
  top: 18px;
  font-size: 12px;
  text-align: right;
  color: gray;
}
</style>
